.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.text-blue {
    color: #009ef7!important
}

.successoption {
    color: #03bd07;
}

.progressBar30 {}

.hiddenColor {
    background-color: #cccccc !important;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.grey-bg{
    background: #f4f4f4;
}

.question-glow-effect{
    -webkit-box-shadow:0px 0px 9px 3px rgba(101,107,105,0.23);
    -moz-box-shadow: 0px 0px 9px 3px rgba(101,107,105,0.23);
    box-shadow: 0px 0px 9px 3px rgba(101,107,105,0.23);
    margin-top: 15px;
    padding:0;
    border-radius: 15px;
}

.question-title-header{
    border-bottom: 1.5px solid #d1cece !important;
}

.column-delete-icon{
    -webkit-text-stroke: 1px white;
}

/* .survey-table .rdt_TableHead{
    display:none;
} */

.plus-button{
    position: relative;
    height:0px;
}